<template>
  <v-row justify="center">
    <v-dialog v-model="addFilterDialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Ajouter un filtre
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Ajouter un Filtre</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formAddFilter">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="formAddFilterField"
                    :items="headers"
                    item-text="text"
                    item-value="value"
                    label="Select"
                    return-object
                    single-line
                    dense
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="formAddFilterSetting"
                    :items="filterSetting"
                    item-text="text"
                    item-value="value"
                    label="Select"
                    single-line
                    dense
                    @change="onFormAddFilterSetting"
                    required
                  ></v-select>
                </v-col>
                <v-col>
                  <template v-if="isNaN(formAddFilterSetting)">
                    <v-text-field
                      v-model="formFilterText"
                      label="Texte pour trier"
                    ></v-text-field>
                  </template>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="addFilterDialog = false">
            Fermer
          </v-btn>
          <v-btn color="blue darken-1" text @click="addFilter"> Ajouter </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "AddFilterForm",
  data: () => ({
    formFilterText: undefined,
    formAddFilterField: {},
    formAddFilterSetting: 1,
    addFilterDialog: false,
  }),
  props: ["headers", "filterSetting"],
  methods: {
    addFilter() {
      const filters = [
        this.formAddFilterField.value,
        this.formAddFilterSetting,
      ];
      if (this.formFilterText) {
        filters.push(this.formFilterText);
      }
      this.$store.dispatch("getContacts", {
        filter: filters.join(":"),
        page: 1,
      });
      this.addFilterDialog = false;
    },
    onFormAddFilterSetting(select) {
      if (isNaN(select.value)) {
        this.formFilterText = undefined;
      }
      return select.value;
    },
  },
};
</script>
