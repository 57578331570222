<template>
  <v-app>
    <v-app-bar app class="fanak-header">
      <v-img
        alt="Fanak Logo"
        class="shrink mr-2"
        contain
        :src="logo"
        transition="scale-transition"
        width="60"
      />
      <v-spacer></v-spacer>
      <v-img
        alt="Logo title Fanak"
        class="shrink mt-1 hidden-sm-and-down"
        contain
        min-width="150"
        :src="logoTitle"
        width="150"
      />

      <v-spacer></v-spacer>
      <template v-if="email">
        <v-menu left bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="#000">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-img :src="logo"></v-img>
              </v-list-item-avatar>
            </v-list-item>

            <v-list-item link>
              <v-list-item-content>
                <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon>mdi-menu-down</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list nav dense>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                @click="listClick(item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>

    <v-main class="main-container">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import logo from "./assets/logo.png";
import logoTitle from "./assets/logo-titre.png";
import { mapState } from "vuex";

import "./assets/styles.css";

export default {
  name: "App",

  data: () => ({
    logo: logo,
    logoTitle: logoTitle,
    items: [{ text: "Se déconnecter", icon: "mdi-logout", id: "logout" }],
    //
  }),
  computed: mapState({
    email: (state) => state.user.email,
  }),
  methods: {
    listClick(item) {
      if (item.id === "logout") {
        this.$store.dispatch("logout");
        this.$router.push({ name: "Login" });
      }
    },
  },
};
</script>
