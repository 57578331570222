<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="d-flex home-page-title">
            <h1>{{ countTotal }} contacts dans la sélection active</h1>
            <div class="csv-export"><csv-export :headers="headers" /></div>
          </div>
          <div>
            <v-row justify="center">
              <v-col cols="12">
                <v-pagination
                  v-model="page"
                  class="my-4"
                  :length="countPages"
                  @next="goToPage(page + 1)"
                  @previous="goToPage(page - 1)"
                  @input="(pageNumber) => goToPage(pageNumber)"
                ></v-pagination>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <div class="d-flex">
            <div>Nombre de contacts par page: &nbsp;</div>
            <div class="select-wrapper">
              <v-select
                v-model="itemPerPageDefault"
                :items="itemsPerPageSelect"
                item-text="value"
                item-value="value"
                label="Select"
                @change="changeItemsPerPage"
                return-object
                single-line
                dense
              ></v-select>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="d-flex">
            <div>tries actifs:</div>
            <div>
              <div v-for="(sortItem, i) in sorts" :key="i" class="d-inline">
                <v-chip close @click:close="removeSort(sortItem)"
                  >{{ sortDisplay(sortItem) }}
                </v-chip>
              </div>
            </div>
            <div class="add-sort-form">
              <add-sort-form :headers="headers" />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="d-flex">
            <div>Filtres actifs:</div>
            <div>
              <div v-for="(filterItem, i) in filters" :key="i" class="d-inline">
                <v-chip close @click:close="removeFilter(filterItem)"
                  >{{ filterDisplay(filterItem) }}
                </v-chip>
              </div>
            </div>
            <div class="add-filter-form">
              <add-filter-form
                :headers="headers"
                :filter-setting="filterSetting"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="filterQuery">Filtrer les non quali</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="primary" dark class="mb-2" @click="editItem({})">
            Ajouter un contact
          </v-btn>
          <edit-form
            :headers="headers"
            :open-dialog="dialogEdit"
            :item="editedItem"
        /></v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="tableHeaders"
      :items="contacts"
      item-key="_id"
      class="elevation-1 data-table"
      :disable-pagination="true"
      :hide-default-footer="true"
      :dense="true"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="duplicateItem(item)">
          mdi-content-duplicate
        </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:item.date_created="{ item }">
        <span>{{ formatDate(item.date_created) }}</span>
      </template>
      <template v-slot:item.date_updated="{ item }">
        <span>{{ formatDate(item.date_updated) }}</span>
      </template>
    </v-data-table>
    <delete-dialog :item="deletedItem" :openDialog="dialogDelete" />
    <notifications-loader />
  </div>
</template>

<script>
import store from "../store/index.js";
import AddSortForm from "../components/AddSortForm.vue";
import AddFilterForm from "../components/AddFilterForm.vue";
import CsvExport from "../components/CsvExport.vue";
import EditForm from "../components/EditForm.vue";
import DeleteDialog from "../components/DeleteDialog.vue";
import NotificationsLoader from "../components/NotificationsLoader.vue";
import { mapState } from "vuex";

export default {
  name: "HomePage",
  data: () => ({
    filterSetting: [
      {
        text: "Existe",
        value: 1,
      },
      {
        text: "N'Existe pas",
        value: 0,
      },
      {
        text: "est vide",
        value: -1,
      },
      {
        text: "Commence par",
        value: "begin",
      },
      {
        text: "Contient",
        value: "contain",
      },
    ],
    headers: [
      {
        text: "Civilité",
        value: "civility",
        sortable: false,
      },
      {
        text: "Nom",
        value: "last_name",
        sortable: false,
      },
      {
        text: "Prénom",
        value: "first_name",
        sortable: false,
      },
      { value: "role", text: "Fonction métier", sortable: false },
      {
        value: "organization_name",
        text: "Nom de l'organisation",
        sortable: false,
      },
      {
        value: "organization_address",
        text: "Adresse postale de l'organisation",
        sortable: false,
      },
      {
        value: "organization_city",
        text: "Ville de l'organisation",
        sortable: false,
      },
      {
        value: "organization_zip",
        text: "Code postal de l'organisation",
        sortable: false,
      },
      { value: "region", text: "région de l'organisation", sortable: false },
      {
        value: "organization_country",
        text: "pays de l'organisation",
        sortable: false,
      },
      {
        value: "tel_type",
        text: "Type de téléphone: Bureau, perso, mobile",
        sortable: false,
      },
      { value: "phone", text: "numéro de tel", sortable: false },
      { value: "email", text: "email", sortable: false },
      {
        value: "code_action",
        text: "Code Action Fonds Fanak",
        sortable: false,
      },
      { value: "notes", text: "Notes libres", sortable: false },
      { value: "address", text: "Adresse postale", sortable: false },
      { value: "city", text: "Ville", sortable: false },
      { value: "zip", text: "code postal", sortable: false },
      { value: "country", text: "Pays", sortable: false },

      { value: "lang", text: "langue: fr, en ou ar", sortable: false },
      {
        value: "ymlp_date",
        text: "date d'entré dans la base YMLP",
        sortable: false,
      },
      {
        value: "ymlp_groups",
        text: "groupes d'appartenance dans la base YMLP",
        sortable: false,
      },
      {
        value: "date_created",
        text: "Date de création",
        sortable: false,
      },
      {
        value: "date_updated",
        text: "Date de mise à jour",
        sortable: false,
      },
    ],
    editedItem: {},
    deletedItem: {},
    dialogEdit: false,
    dialogDelete: false,
    itemPerPageDefault: { value: 50 },
    itemsPerPageSelect: [
      { value: 25 },
      { value: 50 },
      { value: 100 },
      { value: 200 },
      { value: 500 },
    ],
  }),
  computed: {
    ...mapState({
      contacts: (state) => state.contacts,
      itemsPerPage: (state) => state.pager.itemsPerPage,
      countTotal: (state) => state.pager.totalItems,
      sorts: (state) => state.sorts,
      filters: (state) => state.filters,
      countPages: (state) =>
        Math.ceil(state.pager.totalItems / state.pager.itemsPerPage),
    }),
    tableHeaders() {
      return [
        { text: "Actions", value: "actions", sortable: false },
        ...this.headers,
      ];
    },
    page: {
      get() {
        return this.$store.state.pager.currentPage;
      },
      set() {},
    },
  },
  methods: {
    formatDate(date) {
      return date ? new Date(date).toLocaleString() : "";
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },
    deleteItem(item) {
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    duplicateItem(item) {
      const itemProps = Object.getOwnPropertyNames(item);
      const forbiddenProps = ["date_created", "date_updated", "__ob__", "_id"];
      const duplicatedItem = {};
      itemProps.map((prop) => {
        if (
          forbiddenProps.findIndex(
            (forbiddenProp) => forbiddenProp === prop
          ) === -1
        ) {
          duplicatedItem[prop] = item[prop];
        }
      });
      this.$store.dispatch("saveContact", duplicatedItem);
    },

    sortDisplay(sortItem) {
      const a = sortItem.split(":");
      const fieldName = this.headers.find((item) => item.value === a[0]).text;
      return fieldName + " " + (parseInt(a[1], 10) === 1 ? "ASC" : "DESC");
    },
    removeSort(sort) {
      this.$store.dispatch("removeSort", sort);
      store.dispatch("getContacts", {});
    },
    filterDisplay(filterItem) {
      const a = filterItem.split(":");
      const fieldName = this.headers.find((item) => item.value === a[0]).text;
      const type = this.filterSetting.find((item) => item.value == a[1]).text;
      if (a.length === 3) {
        return fieldName + " " + type + " " + a[2];
      }

      return fieldName + " " + type;
    },
    removeFilter(filter) {
      this.$store.dispatch("removeFilter", filter);
      store.dispatch("getContacts", { page: 1 });
    },
    goToPage(page) {
      store.dispatch("getContacts", { page });
    },
    changeItemsPerPage(select) {
      store.dispatch("getContacts", { limit: select.value, page: 1 });
    },
    filterQuery() {
      const filterOption = "last_name:1";
      store.dispatch("getContacts", {
        page: 1,
        filter: filterOption,
      });
    },
  },

  mounted: async () => {
    store.dispatch("getContacts", {});
  },

  components: {
    AddSortForm,
    AddFilterForm,
    CsvExport,
    EditForm,
    DeleteDialog,
    NotificationsLoader,
  },
};
</script>
