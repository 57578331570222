<template>
  <v-dialog v-model="dialogEdit" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <!-- lang -->
              <v-select
                v-model="editedItem[headers[19].value]"
                :label="headers[19].text"
                :items="selectLang"
                item-text="text"
                item-value="value"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12">
              <!-- civilité -->
              <v-text-field
                v-model="editedItem[headers[0].value]"
                :label="headers[0].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <!-- Nom -->
              <v-text-field
                v-model="editedItem[headers[1].value]"
                :label="headers[1].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <!-- Prénom -->
              <v-text-field
                v-model="editedItem[headers[2].value]"
                :label="headers[2].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <!-- role -->
              <v-text-field
                v-model="editedItem[headers[3].value]"
                :label="headers[3].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <!-- organization name -->
              <v-text-field
                v-model="editedItem[headers[4].value]"
                :label="headers[4].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem[headers[5].value]"
                :label="headers[5].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem[headers[6].value]"
                :label="headers[6].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem[headers[7].value]"
                :label="headers[7].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem[headers[8].value]"
                :label="headers[8].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem[headers[9].value]"
                :label="headers[9].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem[headers[10].value]"
                :label="headers[10].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem[headers[11].value]"
                :label="headers[11].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem[headers[12].value]"
                :label="headers[12].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem[headers[13].value]"
                :label="headers[13].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem[headers[14].value]"
                :label="headers[14].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem[headers[15].value]"
                :label="headers[15].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem[headers[16].value]"
                :label="headers[16].text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem[headers[17].value]"
                :label="headers[17].text"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> Annuler </v-btn>
        <v-btn color="blue darken-1" text @click="save"> Sauvegarder </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditForm",
  data() {
    return {
      editedItem: this.item,
      dialogEdit: this.openDialog,
      selectLang: [
        {
          text: "Français",
          value: "fr",
        },
        {
          text: "Anglais",
          value: "en",
        },
        {
          text: "Arabe",
          value: "ar",
        },
      ],
    };
  },
  props: {
    headers: { type: Array },
    openDialog: {
      type: Boolean,
      default: false,
    },
    item: { type: Object, default: () => {} },
  },
  computed: {
    formTitle() {
      return Object.keys(this.item).length === 0
        ? "Nouveau contact"
        : "Edition d'un contact existant";
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },
    close() {
      this.dialogEdit = false;
    },
    save() {
      this.$store.dispatch("saveContact", this.editedItem);
      this.close();
    },
  },
  watch: {
    openDialog: function (newVal) {
      this.dialogEdit = newVal;
    },
    item: function (newVal) {
      this.editedItem = newVal;
      this.dialogEdit = true;
    },
  },
};
</script>
