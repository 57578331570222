export default {
  request: ({ url, method, token, body, notJson }) => {
    const headers = { "Content-Type": "application/json" };

    if (token) {
      headers["Authorization"] = "Bearer " + token;
    }

    return fetch(url, {
      method: method,
      headers: headers,

      // pass in the information from our form
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok && response.status === 401 && token) {
          // token not valid, redirect!
          window.location.href = "login";
        }
        if (notJson) {
          return response;
        }
        return response.json();
      })
      .catch((error) => console.warn("error: ", error));
  },
};
