<template>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <v-card>
      <v-card-title class="text-h5"
        >Etes vous sûr de vouloir supprimer ce contact ?</v-card-title
      >
      <v-card-text>
        <p>Cet contact sera définitivement supprimé.</p>
        <ul>
          <li>{{ editedItem._id }}</li>
          <li>{{ editedItem.email }}</li>
          <li>{{ editedItem.first_name }}</li>
          <li>{{ editedItem.last_name }}</li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteDialog",
  data() {
    return {
      dialogDelete: this.openDialog,
      editedItem: this.item,
    };
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    item: { type: Object, default: () => {} },
  },

  methods: {
    deleteItemConfirm() {
      this.$store.dispatch("deleteContact", this.editedItem._id);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },
  watch: {
    openDialog: function (newVal) {
      this.dialogDelete = newVal;
    },
    item: function (newVal) {
      this.editedItem = newVal;
      this.dialogDelete = true;
    },
  },
};
</script>
