export default {
  setUser: (user) => {
    return localStorage.setItem("user", JSON.stringify(user));
  },
  unsetUser: () => {
    return localStorage.removeItem("user");
  },
  getUser: () => {
    return JSON.parse(localStorage.getItem("user"));
  },
};
