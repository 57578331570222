<template>
  <v-row justify="center">
    <v-dialog v-model="addSortDialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Ajouter un trie
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Ajouter un trie</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formAddSort">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="formAddSortField"
                    :items="headers"
                    item-text="text"
                    item-value="value"
                    label="Select"
                    return-object
                    single-line
                    dense
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="formAddSortSetting"
                    :items="sortSetting"
                    item-text="text"
                    item-value="value"
                    label="Select"
                    return-object
                    single-line
                    dense
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="addSortDialog = false">
            Fermer
          </v-btn>
          <v-btn color="blue darken-1" text @click="addSort"> Ajouter </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "AddSortForm",
  data: () => ({
    formAddSortField: {},
    addSortDialog: false,
    formAddSortSetting: {},
    sortSetting: [
      {
        text: "Ascendant",
        value: 1,
      },
      {
        text: "Descendant",
        value: -1,
      },
    ],
  }),
  props: ["headers"],
  methods: {
    addSort() {
      this.$store.dispatch("getContacts", {
        sort: this.formAddSortField.value + ":" + this.formAddSortSetting.value,
        page: 1,
      });
      this.addSortDialog = false;
    },
  },
};
</script>
