<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="notifSuccess">
      {{ messageSuccess }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="notifSuccess = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="notifError">
      {{ messageError }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="notifError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "HomePage",
  data() {
    return {
      notifSuccess: false,
      notifError: false,
    };
  },
  computed: {
    ...mapState({
      overlay: (state) => state.notification.pending,
    }),
    notifSuccessFromStore: {
      get() {
        return this.$store.state.notification.success;
      },
      set(notifSuccess) {
        return notifSuccess;
      },
    },
    notifErrorFromStore: {
      get() {
        return this.$store.state.notification.error;
      },
      set(notifError) {
        return notifError;
      },
    },
    messageSuccess() {
      return this.$store.state.notification.message;
    },
    messageError() {
      return this.$store.state.notification.error;
    },
  },
  watch: {
    notifSuccessFromStore(newVal) {
      this.notifSuccess = newVal;
    },
    notifErrorFromStore(newVal) {
      this.notifError = newVal;
    },
  },
};
</script>
